<template>
    <div>
        <div v-if="isLoading" class="row">
            <mercur-spinner></mercur-spinner>
        </div>
        <div v-else class="row">
            <form @submit.prevent="isEditing ? update() : create()">
                <div class="col col-4">
                    <mercur-input
                        v-model="form.configurationName"
                        :class="getValidationClass($v, 'form.configurationName')"
                    >
                        Configuration Name
                        <template #note>
                            <span class="form-error" v-if="!$v.form.configurationName.required">Configuration name is required</span>
                        </template>
                    </mercur-input>
                </div>
                <div class="mt-3 col col-12">
                    <h3>System Fields</h3>
                    <mercur-checkbox
                        v-model="selectedFields"
                        :disabled="requiredSystemFields.includes(field.fieldName)"
                        v-for="field in systemFields"
                        :key="field.fieldId"
                        :value="field.fieldName"
                        class="mr-2 mb-3"
                    >
                        {{ field.fieldName }}
                    </mercur-checkbox>
                    <h3>Custom Fields</h3>
                    <mercur-checkbox
                        v-model="selectedFields"
                        v-for="field in customFields"
                        :key="field.fieldId"
                        :value="field.fieldName"
                        class="mr-2 mb-1"
                    >
                        {{ field.fieldName }}
                    </mercur-checkbox>
                    <div class="mt-5 u-text-left">
                        <mercur-button
                            type="submit"
                            v-if="hasPermission('MerchantCentral/updateConfiguration')"
                            :disabled="isDisabled"
                            class="btn btn-primary"
                        >
                            {{ isEditing ? 'Save' : 'Add Configuration' }}
                        </mercur-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'

export default {
    props: ['isEditing'],
    mixins: [FormMixin],
    name: 'ProductFeedContent',
    data: () => ({
        selectedFields: ['productHash', 'sku', 'quantity'],
        requiredSystemFields: ['productHash', 'sku', 'quantity'],
        form: {},
        isLoading: false,
        isDisabled: false,
    }),

    validations: {
        form: {
            configurationName: {
                required,
            },
        },
    },

    methods: {
        ...mapActions('productfeed', ['fetchFields', 'getCurrentConfiguration']),

        setFields () {
            // Pre-select fields
            let selectedCurrentFields = this.currentConfiguration.fields

            selectedCurrentFields = this.$store.getters['productfeed/getFieldsByIds'](selectedCurrentFields)

            selectedCurrentFields.forEach((field) => {
                if (!this.selectedFields.includes(field)) {
                    this.selectedFields.push(field.fieldName)
                }
            })
            // Assign Configuration Name
            this.form.configurationName = this.currentConfiguration.configurationName
        },

        async update () {
            this.isDisabled = true
            const url = CONFIG.API.ROUTES.PRODUCT_FEEDS.CONFIGURATIONS.UPDATE
            const fields = this.$store.getters['productfeed/getFieldsByNames'](this.selectedFields)
            const data = {
                configurationId: this.$route.params.configurationId,
                configurationName: this.form.configurationName,
                fields: fields ? fields.map(field => field.fieldId) : null,
            }

            this.post(url, data, 'Configuration was updated').then((res) => {
                if (res) {
                    this.$router.replace({
                        name: 'ConfigurationsWrapper',
                    })
                }
            }).catch(({ err }) => {
                this.$root.$emit('notification:global', {
                    message: err.data[0].error,
                    type: 'error',
                })
            }).finally(() => {
                this.isDisabled = false
            })
        },

        async create () {
            this.isDisabled = true
            const url = CONFIG.API.ROUTES.PRODUCT_FEEDS.CONFIGURATIONS.CREATE
            const fields = this.$store.getters['productfeed/getFieldsByNames'](this.selectedFields)
            const data = {
                configurationName: this.form.configurationName,
                fields: fields ? fields.map(field => field.fieldId) : null,
            }

            this.post(url, data, 'Configuration was created').then((res) => {
                if (res) {
                    this.$router.replace({
                        name: 'ConfigurationsWrapper',
                    })
                }
            }).catch(({ data }) => {
                return this.$root.$emit('notification:global', {
                    message: data.data[0].error,
                    type: 'error',
                })
            }).finally(() => {
                this.isDisabled = false
            })
        },
    },

    computed: {
        ...mapGetters('productfeed', ['systemFields', 'customFields']),
        ...mapState('productfeed', ['fields', 'currentConfiguration']),
    },

    async mounted () {
        this.isLoading = true
        /// Fetch fields
        this.fetchFields()

        /// If it is in editing then get the current product feed by id
        if (this.isEditing) {
            await this.getCurrentConfiguration(this.$route.params.configurationId)
            this.setFields()
        } else {
            this.form.configurationName = ''
        }
        this.isLoading = false
    },

}
</script>
